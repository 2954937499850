import React from "react";
import UserProvider from "./userProvider";
import QuizProvider from "./quizProvider";

export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <QuizProvider>
        {element}
      </QuizProvider>
    </UserProvider>
  )
};